import { Component,OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventHoveringArg } from '@fullcalendar/angular';
import { FestivalService } from '../festival.service';
import { Festival } from '../../models/Festival';
import { EventInput } from '@fullcalendar/angular';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
    selector: 'festival-calendar',
    templateUrl: './festival-calendar.html',
    styleUrls: ['./festival-calendar.css']
})
export class FestivalCalendarComponent implements OnInit {

    calendarVisible = true;
    calendarOptions: CalendarOptions = {}
    currentEvents: EventApi[] = [];
    festivals: Festival[];
    festEvents: EventInput[];
    lang: string;

    constructor(private festivalservice: FestivalService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.lang = params["lang"];
            this.festEvents = [];
            this.LoadCalendar();
        });
    }

    ngOnInit() {
        
    }

    LoadCalendar() {
        this.spinner.show();
        this.festivalservice.getAll().subscribe((festivals: Festival[]) => {
            this.festivals = festivals;
            festivals.forEach(fest => {
                if (this.lang == "english") {
                    this.festEvents.push(
                        {
                            id: String(fest.PKFestivalID),
                            title: fest.Festival_EN,
                            start: formatDate(fest.FestivalDate, "yyyy-MM-dd", 'en-US'),
                            backgroundColor: '#c21010'
                        });
                }
                if (this.lang == "telugu") {
                    this.festEvents.push(
                        {
                            id: String(fest.PKFestivalID),
                            title: fest.Festival_TE,
                            start: formatDate(fest.FestivalDate, "yyyy-MM-dd", 'en-US'),
                            backgroundColor: '#209113'
                        }
                    );
                }
            });

            var ismobile = (window.navigator.userAgent.indexOf('Android') >= 0 || window.navigator.userAgent.indexOf('iPhone') >= 0);
            var _right = ismobile ? "listWeek,dayGridMonth" : 'listWeek,timeGridWeek,dayGridMonth';
            var _initialView = ismobile ? "listWeek" : "dayGridMonth";

            this.calendarOptions = {
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: _right
                },
                contentHeight: 'auto',
                initialView: _initialView,
                initialEvents: this.festEvents, // alternatively, use the `events` setting to fetch from a feed
                weekends: true,
                editable: false,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                //select: this.handleDateSelect.bind(this),
                eventMouseEnter: this.handleEventMouseEnter.bind(this),
                eventMouseLeave: this.handleEventMouseOut.bind(this),
                eventClick: this.handleEventClick.bind(this),
                eventsSet: this.handleEvents.bind(this)
                /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
            };

            this.spinner.hide();
        })
    }

    //handleDateSelect(selectInfo: DateSelectArg) {
    //    const title = prompt('Please enter a new title for your event');
    //    const calendarApi = selectInfo.view.calendar;

    //    calendarApi.unselect(); // clear date selection

    //    if (title) {
    //        calendarApi.addEvent({
    //            id: createEventId(),
    //            title,
    //            start: selectInfo.startStr,
    //            end: selectInfo.endStr,
    //            allDay: selectInfo.allDay
    //        });
    //    }
    //}

    handleEventClick(clickInfo: EventClickArg) {
        alert(`'${clickInfo.event.title}'`);           
       
    }

    handleEventMouseEnter(eventInfo: EventHoveringArg) {
        $(eventInfo.el).find('.fc-event-title').attr('title', eventInfo.event.title);
        $(eventInfo.el).find('.fc-event-title').attr('data-toggle', "tooltip");
        $('[data-toggle="tooltip"]').tooltip();
    }


    handleEventMouseOut(eventInfo: EventHoveringArg) {
        //$(this).css('z-index', 8);
        //$('.tooltipevent').remove();
        $(eventInfo.el).find('.fc-event-title').removeAttr('title');
        $(eventInfo.el).find('.fc-event-title').removeAttr('data-toggle');
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }
}

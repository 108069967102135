import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Horoscope } from '../models/Horoscope';



@Injectable()
export class HoroscopeService {
    private _baseaddress = "/api/HoroscopeAPI";

    horoscopequeryparams: BehaviorSubject<any> = new BehaviorSubject<any>({});
    constructor(private http: HttpClient) {

    }
    getAllhoroscopes() {
        return this.http.get(`${this._baseaddress}/GetHoroscopes`);
    }

    gethoroscopes(horoscopetype: string, horoscopelang: string) {
        return this.http.get(`${this._baseaddress}/GetHoroscopes/${horoscopetype}/${horoscopelang}`);
    }

    getAllHoroscopesByDate(signname: string, date: string,lang:string) {
        return this.http.get(`${this._baseaddress}/GetAllHoroscopesByDate?signname=${signname}&horoscopedate=${date}&horoscopelang=${lang}`);
    }

    isHoroscopeExist(horoscope: Horoscope) {
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/IsHoroscopeExist`, horoscope, { headers: headers });

    }

    insert(horoscope: Horoscope) {        
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/Insert`, horoscope, { headers: headers });
    }

    insertbulk(horoscopes: Horoscope[]) {        
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/InsertBulk`, horoscopes, { headers: headers });
    }

    update(horoscope: Horoscope) {
        let headers = new HttpHeaders();
        return this.http.put(`${this._baseaddress}/Update`, horoscope, { headers: headers });
    }

    delete(horoscopeId: number,horoscopetype:string) {
        let headers = new HttpHeaders();
        return this.http.delete(`${this._baseaddress}/Delete?id=${horoscopeId}&horoscopetype=${horoscopetype}`, { headers: headers });
    }

    deleteByDate(fromdate: string,todate:string, horoscopetype: string,lang:string) {
        let headers = new HttpHeaders();
        return this.http.delete(`${this._baseaddress}/DeleteByDate?fromdate=${fromdate}&todate=${todate}&horoscopetype=${horoscopetype}&horoscopelang=${lang}`, { headers: headers });
    }

    importdata(lstHoroscope: any,horscopetype:string,lang:string) {

        //const formData: FormData = new FormData();
        //if (file != undefined)
        //    formData.append('excelfile', file, file.name);
        //formData.append("horoscopeType", horscopetype);
        

        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/Importdata?horoscopetype=${horscopetype}&horoscopelang=${lang}`, lstHoroscope, { headers: headers });
    }

    storeparams(horoscopetype:string,lang:string) {
        this.horoscopequeryparams.next({ horoscopetype: horoscopetype,lang:lang });
    }
}

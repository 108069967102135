import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[isValidStartdate][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: StartDateValidator, multi: true }
    ]
})
export class StartDateValidator implements Validator {   
   
    validate(c: FormControl) {
        let isValid = new Date(c.value).getDay() == 0;
        if (window.location.href.indexOf("weekly") == -1)
            isValid = true;
        
        if (isValid) {
            return null;
        } else {
            return {
                isValidStartdate: {
                    valid: false
                }
            };
        }       

    }
}

import { Component } from '@angular/core';
import { AuthenticateService } from '../../login/authenticate.service';

@Component({
    selector: 'user-home',
    templateUrl: './userhome.component.html',
})
export class UserHomeComponent {
    constructor(private authService: AuthenticateService) {
        //this.authService.GetLoggedInUser().subscribe(x => {

        //});

    }
}

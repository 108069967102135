import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from '../models/Contact';
import { SunSigns_Master } from '../models/SunSigns_Master';

@Injectable()
export class SunSignService {
    private _baseaddress = "/api/SunSignsAPI";
    userData: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(new Contact());

    constructor(private http: HttpClient) {

    }

    getAllSunSigns() {
        return this.http.get(`${this._baseaddress}/GetSunSigns`);
    }

    insert(file: File, sign: SunSigns_Master) {
        
        const formData: FormData = new FormData();
        if (file != undefined)
        formData.append('signimage', file, file.name);
        formData.append("updatesign", JSON.stringify(sign));
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/Insert`, formData, { headers: headers });
    }

    update(file: File, sign: SunSigns_Master) {
        const formData: FormData = new FormData();
        if (file != undefined)
        formData.append('signimage', file, file.name);
        formData.append("updatesign", JSON.stringify(sign));
        let headers = new HttpHeaders();       
        return this.http.put(`${this._baseaddress}/Update`, formData, { headers: headers });
    }

   delete(signId:number ) {
       let headers = new HttpHeaders();
       return this.http.delete(`${this._baseaddress}/Delete?id=${signId}`, { headers: headers });
    }
}

import { Component, ViewChild, ElementRef } from '@angular/core';
import { Contact } from '../models/Contact';
import { AuthenticateService } from '../login/authenticate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
    isExpanded = false;
    isLoggedIn: boolean = false;


    contact: Contact = new Contact();
    @ViewChild("loginmodal", { static: false }) public loginmodal: ElementRef;

    constructor(private authService: AuthenticateService, private router: Router) {       
        this.authService.userData.subscribe((userdata: Contact) => {
            this.contact = userdata;            
            if (this.contact.PKContactId == undefined)
                this.isLoggedIn = false;
            else
                this.isLoggedIn = true;
        });
    }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    }

}

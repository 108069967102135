import { Injectable } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthenticateService, private spinner: NgxSpinnerService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(retry(0), catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            this.spinner.hide();
            if (error.error != null) {
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // server-side error
                    errorMessage = `${error.error.title}\nError Code: ${error.status}\nMessage: ${error.message}`;
                }
            }
            else if (error.status == 401) {
                if (error.url.indexOf("GetLoggedInUser") != -1)
                    //alert("Session has been expired\nPlease login");  
                errorMessage = `Error code: ${error.status}\nMessage: Unauthorized access`;
            }
//            window.alert(errorMessage);
            return throwError(errorMessage);
        })
        )
    }
}  

import { Component, ViewChild, ElementRef } from '@angular/core';
import { SunSignService } from './sunsigns.service';
import { SunSigns_Master } from '../models/SunSigns_Master';


@Component({
    selector: 'sunsigns',
    templateUrl: './sunsigns.component.html',
    styleUrls:['./sunsigns.component.css']
})
export class SunSignsComponent {
    @ViewChild("btnclose_signmodal", { static: false }) public modalpop: ElementRef;
    @ViewChild("btnclose_deletemodal", { static: false }) public deletemodalpop: ElementRef;
    @ViewChild("fileinput", { static: false }) public fileinput: ElementRef;
    @ViewChild("addForm", { static: false }) public modalform: any;
    sign: SunSigns_Master = new SunSigns_Master();
    sunsigns: SunSigns_Master[] = [];
    file: File;
    action: string = 'Add';

    constructor(private sunsignService: SunSignService) {
        this.getAllSigns();
    }

    getAllSigns() {
        this.sunsignService.getAllSunSigns().subscribe((signs: SunSigns_Master[]) => {
            this.sunsigns = signs;
        });
    }

    getSign(id: number) {
        this.sign = this.sunsigns.find(x => x.SignId == id);
    }

    FileChange(files: FileList) {
        this.file = files[0];
    }

    addSign() {
        this.sunsignService.insert(this.file, this.sign).subscribe((signs: SunSigns_Master[]) => {
            this.sunsigns = signs;
            this.modalpop.nativeElement.click();
        });
    }

    updateSign() {
        this.sunsignService.update(this.file,this.sign).subscribe((signs: SunSigns_Master[]) => {
            this.sunsigns = signs;
            this.modalpop.nativeElement.click();
        });
    }

    deleteSign() {
        this.sunsignService.delete(this.sign.SignId).subscribe((signs: SunSigns_Master[]) => {
            this.sunsigns = signs;
            this.deletemodalpop.nativeElement.click();
        });
    }

    resetform() {
        this.sign = new SunSigns_Master();
        this.modalform.reset();
        this.fileinput.nativeElement.value = "";
    }
} 

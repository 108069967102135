import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginComponent } from './login/login.component';
import { StartDateValidator } from './horoscope/weekly/startdate.validation';
import { TruncatePipe } from './pipes/truncate.pipe';
import { OrderrByPipe } from './pipes/order.pipe';
import { SunSignsComponent } from './sunSigns/sunsigns.component';


@NgModule({
    declarations: [        
        LoginComponent,
        OrderrByPipe,
        TruncatePipe,
        StartDateValidator,
        SunSignsComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        PaginationModule.forRoot(),
        NgxSpinnerModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot()
    ],
    
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [LoginComponent, CommonModule,
        HttpClientModule,
        FormsModule, PaginationModule,
        NgxSpinnerModule, BsDatepickerModule, DatepickerModule, OrderrByPipe,
        TruncatePipe,
        StartDateValidator],
    //providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class SharedModule { }

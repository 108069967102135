export class SunSigns_Master {

    public SignId: number;
    public SignName_EN: string;
    public SignName_TE: string;
    public FriendlyName: string;
    public Description: string;
    public SignImage: string;
    public CreatedBy: number;
    public CreatedDate: Date;
    public ModifiedBy: number;
    public ModifiedDate: Date;
    public Status: boolean;

    public FileUpload: File;
}

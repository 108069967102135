import { Role } from "./Role";

export class Contact {
    public PKContactId: number;
    public FKRoleId: number;
    public FirstName: string;
    public LastName: string;
    public UserName: string;
    public EmailId: string;
    public Password: string;
    public Phoneno: string;
    public City: string;
    public State: string;
    public Country: string;    
    public CreatedBy: number;
    public ModifiedBy: number;
    public CreatedDate: Date;
    public ModifiedDate: Date;
    public IsActive: boolean;
    public CreatedUser: Contact;
    public ModifiedUser: Contact;
    public Role: Role;
}

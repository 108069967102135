import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from '../models/Contact';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticateService {
    private _baseaddress = "/api/AuthenticateAPI";
    userData: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(new Contact());

    constructor(private http: HttpClient, private router: Router) {

    }

    login(contact: Contact) {
        return this.http.post<any>(`${this._baseaddress}/authenticate`, contact)
            .pipe(map(response => {
                localStorage.setItem('authToken', response.token);
                this.userData.next(response.userDetails);
                return response;
            }));
    }

    GetLoggedInUser() {
        const token = localStorage.getItem('authToken');
        return this.http.get(`${this._baseaddress}/GetLoggedInUser`);
    }

    logout() {
        localStorage.removeItem('authToken');
        this.router.navigate(['/login']);
        this.userData.next(new Contact());
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Festival } from '../models/Festival';

@Injectable()
export class FestivalService {
    private _baseaddress = "/api/FestivalAPI";
    constructor(private http: HttpClient) {

    }

    getAll() {
        return this.http.get(`${this._baseaddress}/GetFestivals`);
    }

    isFestivalExist(festival: Festival) {
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/IsFestivalExist`, festival, { headers: headers });

    }

    insert(festival: Festival) {
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/Insert`, festival, { headers: headers });
    }


    update(festival: Festival) {
        let headers = new HttpHeaders();
        return this.http.put(`${this._baseaddress}/Update`, festival, { headers: headers });
    }

    delete(festId: number) {
        let headers = new HttpHeaders();
        return this.http.delete(`${this._baseaddress}/Delete?id=${festId}`, { headers: headers });
    }


    importdata(lstFestivals: any) {
        //const formData: FormData = new FormData();
        //if (file != undefined)
        //    formData.append('excelfile', file, file.name);        
        let headers = new HttpHeaders();
        return this.http.post(`${this._baseaddress}/Importdata`, lstFestivals, { headers: headers });
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LocationStrategy, HashLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AuthenticateService } from './login/authenticate.service';
import { LoginComponent } from './login/login.component';
import { HttpInterceptorService } from './login/HttpInterceptorService';
import { SunSignService } from './sunSigns/sunsigns.service';
import { FooterComponent } from './footer/footer.component';
import { ErrorInterceptorService } from './login/ErrorInterceptorService';
import { AdminGuard } from './login/admin.guard';
import { HoroscopeService } from './horoscope/horoscope.service';
import { FestivalService } from './festivals/festival.service';
import { SunSignsComponent } from './sunSigns/sunsigns.component';
import { UserHomeComponent } from './user/home/userhome.component';
import { DisplayHoroscopesComponent } from './user/sunsigns/displayHoroscopes.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SharedModule } from './shared.module';
import { FestivalCalendarComponent } from './festivals/festivals calendar/festivalcalendar.component';

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin
])



@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        FooterComponent,
        HomeComponent,        
        UserHomeComponent,
        DisplayHoroscopesComponent,
        FestivalCalendarComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        
        RouterModule.forRoot( [
            { path: '', component: HomeComponent, pathMatch: 'full' },
            { path: 'home', component: HomeComponent},
            //{ path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
            { path: 'admin', loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule) },
            { path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [AdminGuard] },
            { path: 'sunsign/:lang/:sign', component: DisplayHoroscopesComponent },
            { path: 'festivals/:lang', component: FestivalCalendarComponent }

        ]),
        
        NgbModule,
        FullCalendarModule
    ],
    providers: [AuthenticateService, SunSignService, HoroscopeService, FestivalService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
        AdminGuard,
        { provide: APP_BASE_HREF, useValue:'/astrology' },
        //{ provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component,OnInit} from '@angular/core';
import { SunSignService } from '../../sunSigns/sunsigns.service';
import { SunSigns_Master } from '../../models/SunSigns_Master';
import { ActivatedRoute } from '@angular/router';
import { HoroscopeService } from '../../horoscope/horoscope.service';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'displayHoroscopes',
    templateUrl: './displayHoroscopes.component.html',
    styleUrls:['./displayHoroscope.css']
})
export class DisplayHoroscopesComponent implements OnInit {

    signname: string;
    horoscope: any;
    signs: SunSigns_Master[];
    lang: string

    constructor(private route: ActivatedRoute, private horoscopeService: HoroscopeService, private spinner: NgxSpinnerService) {
        
        this.route.params.subscribe(params => {
            this.signname = params["sign"];
            this.lang = params["lang"];
            var _formatedDate = formatDate(new Date(), "MM/dd/yyyy", 'en-US');
            this.getDailyHoroscope(this.signname, _formatedDate);
        })
    }

    ngOnInit() {
        this.horoscope = {};        
    }

    getDailyHoroscope(sign: string, date: string) {
        //this.spinner.show();
        this.horoscopeService.getAllHoroscopesByDate(sign, date,this.lang).subscribe(response => {
            this.spinner.hide();
            this.horoscope = response;           
        });
    }

}

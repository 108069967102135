import { Component, ViewChild ,ElementRef } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { Contact } from '../models/Contact';
import { SunSignService } from '../sunSigns/sunsigns.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'user-login',
    templateUrl: './login.html',
    styleUrls:['./login.component.css']
})
export class LoginComponent {

    @ViewChild("loginmodal", { static: false }) public loginmodal: ElementRef;
    contact: Contact = new Contact();
    constructor(private authService: AuthenticateService, private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) {
       
    }

    login() {
        this.spinner.show();
        this.authService.login(this.contact).subscribe((data: any) => {
            this.spinner.hide();
            this.loginmodal.nativeElement.click();
            if (data.userDetails.Role.Title == "admin")
                this.router.navigate(['/admin','signs']);

        });
    }
}

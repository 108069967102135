import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AuthenticateService } from './authenticate.service';
import { Contact } from '../models/Contact';

@Injectable()
export class AdminGuard implements CanActivate {

    contact: Contact = new Contact();
    constructor(private authService: AuthenticateService, private router: Router) {
        this.authService.userData.subscribe((userdata: Contact) => {
            this.contact = userdata;
        });
    }

    canActivate() {
        if (this.contact.Role != undefined && this.contact.Role.Title == "admin")
            return true;
        else {
            //window.alert("Access denied");
            this.router.navigate(['/admin','login']);
            return false;
        }
    }
}

import { Component } from '@angular/core';
import { Contact } from './models/Contact';
import { AuthenticateService } from './login/authenticate.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'app';
    constructor(private authService: AuthenticateService, private router: Router) {
        const token = localStorage.getItem('authToken');
        if (token) {
            if (window.location.pathname.indexOf('admin') != -1)
                localStorage.setItem("referrer", window.location.pathname.split('/')[3]);

            if (this.authService.userData.value.PKContactId == undefined) {
                this.authService.GetLoggedInUser().subscribe((user: Contact) => {
                    this.authService.userData.next(user);
                    if (window.location.pathname.indexOf('admin') != -1)
                        this.router.navigate(['/admin', localStorage.getItem("referrer")]);
                });
            }
        }
    }
}
